import { createModel } from '@rematch/core';
import { message } from 'antd';
import produce from 'immer';
import { BusTourModelType, BusTourType, PageRequest, PaginationType, RootModel } from '@types';
import { DEFAULT_PAGINATION } from '@commons/constants';
import { Dispatch } from '@redux/store';

const initialState: BusTourModelType = {
  ids: [],
  loading: false,
  editId: null,
  pagination: DEFAULT_PAGINATION,
  criteria: {
    keyword: '',
  },
};

export const busTours = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setIds: produce((state, payload) => {
      state.ids = payload;
    }),
    setPagination: produce((state, payload) => {
      state.pagination = payload;
    }),
    setEditId: produce((state, payload) => {
      state.editId = payload;
    }),
    updateCriteria: produce((state, payload) => {
      state.criteria = payload;
    }),
    updateLoading: produce((state, payload) => {
      state.loading = payload;
    }),
  },
  effects: (dispatch: Dispatch) => ({
    async reloadList(payload, state): Promise<void> {
      const { criteria, pagination } = state.busTours;
      await this.filterAsync({ ...criteria, ...pagination });
    },

    async filterAsync(payload): Promise<void> {
      this.updateLoading(true);
      try {
        let readyPayload = payload;
        if (readyPayload && readyPayload.sort) {
          const clonedPayload = { ...readyPayload };
          const { sort } = clonedPayload;
          if (sort) {
            clonedPayload.sort = sort.map((i) => `${i.property},${i.direction}`).join(',');
          }
          readyPayload = clonedPayload;
        }
        const data: { content: BusTourType[] } & PaginationType =
          await dispatch.busTourEntities.load(readyPayload);
        if (data && data.content) {
          this.setIds(data.content.map((scene) => scene.id));
          this.setPagination((({ content, pageable, ...otherProps }) => otherProps)(data));
        }
        this.updateCriteria(payload);
      } catch (e) {
        console.error(e);
        message.error('Failed to filter');
      } finally {
        this.updateLoading(false);
      }
    },

    async pagingAsync(payload: PageRequest, state): Promise<void> {
      const { criteria = {}, pagination = {} } = state.busTours;
      await this.filterAsync({ ...criteria, ...pagination, ...payload });
    },

    async getAsync(payload, state): Promise<void> {
      this.updateLoading(true);
      try {
        const item = await dispatch.busTourEntities.findById(payload);
        this.setEditId(item.id);
        this.updateLoading(false);
      } catch (e) {
        console.error(e);
        message.error(`Failed to get ${payload}`);
      } finally {
        this.updateLoading(false);
      }
    },

    async deleteAsync(payload, state): Promise<void> {
      this.updateLoading(true);
      try {
        await dispatch.busTourEntities.delete(payload);
        await this.reloadList();
        message.warning(`Item deleted`);
      } catch (e) {
        console.error(e);
        message.error(`Failed to delete`);
      } finally {
        this.updateLoading(false);
      }
    },

    async saveAsync(payload, state): Promise<void> {
      this.updateLoading(true);
      try {
        const result = await dispatch.busTourEntities.save(payload);
        message.success('Save successfully');
        return result;
      } catch (err) {
        console.error(err);
        message.error('Fail to save');
      } finally {
        this.updateLoading(false);
      }
    },
    async publish(payload, state) {
      this.updateLoading(true);
      try {
        const item = await dispatch.busTourEntities.publish(payload);
        this.setEditId(item.id);
        this.updateLoading(false);
        message.success(`Success to ${payload.published ? 'published' : 'unpublished'}`);
      } catch (e) {
        console.error(e);
        message.error(`Fail to ${payload.published ? 'published' : 'unpublished'}`);
      } finally {
        this.updateLoading(false);
      }
    },
    async saveAndPublishAsync(payload, state) {
      this.updateLoading(true);
      try {
        const result = await dispatch.busTourEntities.save(payload);
        await dispatch.busTourEntities.publish({ id: result.id, published: true });
        message.success('Save and published successfully');
      } catch (err) {
        console.error(err);
        message.error('Fail to save and published');
      } finally {
        this.updateLoading(false);
      }
    },
  }),
});

import {
  PageRequest,
  PaginationResponseType,
  ResponseType,
  TravelingCriteria,
  TravelingDtoType,
} from '@types';
import { get, post, put } from './baseAxios';
import { API_TRAVELING } from './urls';

const BASE_URL = API_TRAVELING;

export const filterTravelings = (
  request: PageRequest & TravelingCriteria = {}
): Promise<PaginationResponseType<TravelingDtoType[]>> => {
  const { sort, ...rest } = request;
  const params: any = {
    ...rest,
  };

  if (sort?.length) {
    const firstSort = sort![0];
    params.sort = `${firstSort.property!},${firstSort.direction!.toLowerCase()}`;
  }
  return get(BASE_URL, { params });
};

export const getTraveling = (id: number): Promise<ResponseType<TravelingDtoType>> =>
  get(`${BASE_URL}/${id}`);

export const updateDirection = async (
  id: number,
  data: any
): Promise<ResponseType<TravelingDtoType>> => {
  return put(`${BASE_URL}/${id}/direction`, data);
};

export const updateLocations = async (
  id: number,
  data: any
): Promise<ResponseType<TravelingDtoType>> => {
  return put(`${BASE_URL}/${id}/locations`, data);
};

export const updateRefPoints = async (
  id: number,
  data: any
): Promise<ResponseType<TravelingDtoType>> => {
  return put(`${BASE_URL}/${id}/ref-points`, data);
};

export const updateBgSound = async (
  id: number,
  data: any
): Promise<ResponseType<TravelingDtoType>> => {
  return put(`${BASE_URL}/${id}/bg-sound`, data);
};

export const createFromTrip = async (tripId: number): Promise<ResponseType<TravelingDtoType>> => {
  return post(`${BASE_URL}/from-trip/${tripId}`);
};

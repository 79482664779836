import { CONFIG_TRAVELING_STEPS, DEFAULT_PAGINATION, LOCATION_TYPE } from '@commons/constants';
import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import { getLocation } from '@services/location.service';
import {
  FileModel,
  LatLngType,
  LocationDtoType,
  PageRequest,
  PaginationType,
  RootModel,
  RouteRefPointResponseType,
  RouteRefPointType,
  TravelingCriteria,
  TravelingDtoType,
  TravelingModel,
} from '@types';
import {
  generateTravelingRouteWithLocationIndex,
  getDistanceBetween2Points,
} from '@utils/travelingUtils';
import { message } from 'antd';
import { LabeledValue } from 'antd/lib/select';

const initialState: TravelingModel = {
  ids: [],
  loading: false,
  editId: null,
  pagination: DEFAULT_PAGINATION,
  criteria: null,
  createModalOpened: false,

  // for configuration
  activeLocationId: null,
  editLocationId: null,
  editLocation: null,
  locationIds: [],
  step: Object.values(CONFIG_TRAVELING_STEPS).sort((a, b) => a.orderIndex - b.orderIndex)[0].key,
  locationsChanged: false,
  routeChanged: false,
  refPointsChanged: false,
  newViewAdded: false,
  needsUpdateForm: false,
  audio: null,
  showUpdateBgSoundModal: false,
};

export const traveling = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setIds(state, payload) {
      state.ids = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setEditId(state, payload) {
      state.editId = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setCriteria(state, payload) {
      state.criteria = payload;
    },
    setActiveLocationId(state, payload) {
      state.activeLocationId = payload;
    },
    setEditLocationId(state, payload) {
      state.editLocationId = payload;
      if (!payload) {
        state.editLocation = null;
      }
    },
    setEditLocation(state, payload) {
      if (payload) {
        state.editLocation = payload;
        state.editLocationId = payload.id;
      } else {
        state.editLocation = null;
        state.editLocationId = null;
      }
    },
    setLocationIds(state, payload) {
      state.locationIds = payload;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setLocationsChanged(state, payload) {
      state.locationsChanged = payload;
    },
    setRouteChanged(state, payload) {
      state.routeChanged = payload;
    },
    setRefPointsChanged(state, payload) {
      state.refPointsChanged = payload;
    },
    setNewViewAdded(state, payload) {
      state.newViewAdded = payload;
    },
    setNeedsUpdateForm(state, payload) {
      state.needsUpdateForm = payload;
    },
    setShowUpdateBgSoundModal(state, payload) {
      state.showUpdateBgSoundModal = payload;
    },
    setCreateModalOpened(state, payload) {
      state.createModalOpened = payload;
    },
    setAudio(state, payload: TravelingDtoType) {
      if (payload?.bgSound) {
        state.audio = payload.bgSound;
      } else {
        state.audio = null;
      }
    },
  },
  effects: (dispatch: Dispatch) => ({
    async filterAsync(req?: PageRequest & TravelingCriteria) {
      this.setLoading(true);
      try {
        const data: { content: TravelingDtoType[] } & PaginationType =
          await dispatch.travelingEntities.load(req);

        if (data && data.content) {
          this.setIds(data.content.map((x) => x.id));
          this.setPagination((({ content, ...otherProps }) => otherProps)(data));
        }
        this.setCriteria(req);
      } catch (e) {
        console.error(e);
      } finally {
        this.setLoading(false);
      }
    },
    async pagingAsync(payload: PageRequest, state) {
      const { criteria } = state.traveling;
      const request = {
        ...criteria,
        ...payload,
      };
      await this.filterAsync(request);
    },
    async reloadList(payload, state) {
      const { criteria, pagination } = state.traveling;
      await this.filterAsync({ ...pagination, ...criteria });
    },
    async getAsync(payload, state) {
      this.setLoading(true);
      try {
        const item = await dispatch.travelingEntities.findById(payload);
        this.setEditId(item.id);
        this.setAudio(item);
        if (item.locations?.length) {
          this.setLocationIds(item.locations.map((x) => x.id));
        }
      } catch (e) {
        console.error(e);
        message.error('Failed to get traveling details');
      } finally {
        this.setLoading(false);
      }
    },
    async createFromTrip(trip: LabeledValue, state) {
      this.setLoading(true);
      try {
        const data = await dispatch.travelingEntities.createFromTrip(Number(trip.value));
        const {
          pagination: { size = DEFAULT_PAGINATION.size },
        } = state.traveling;
        await this.filterAsync({ page: 0, size });
        return data;
      } catch (e) {
        console.error(e);
        message.error('Failed to create traveling for trip ' + trip.label);
        return null;
      } finally {
        this.setLoading(false);
      }
    },
    async updateTravelingLocations(payload: LocationDtoType[], state) {
      const updatedLocations: LocationDtoType[] = (payload || []).map((l, i) => ({
        ...l,
        orderIndex: i + 1,
      }));
      await dispatch.locationEntities.saves(updatedLocations);
      await this.setLocationIds(updatedLocations.map((l) => l.id));
      this.setLocationsChanged(true);
    },
    async generateRouteForTraveling(_, state) {
      this.setLoading(true);
      try {
        const { editId, locationIds = [] } = state.traveling;
        const { ids: entities } = state.travelingEntities;
        const { ids: locationEntities = {} } = state.locationEntities;
        if (!editId || !entities || !entities[editId]?.direction) {
          return;
        }

        const entity: TravelingDtoType = entities[editId];
        const locations: LocationDtoType[] = locationIds
          .map((i) => locationEntities[i])
          .filter((x) => !!x);

        let waypoints: LocationDtoType[] | undefined;
        if (locations) {
          waypoints = locations
            .filter((l) => l.type !== LOCATION_TYPE.VIEW)
            .sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0));
        }
        const route = generateTravelingRouteWithLocationIndex(
          entity.direction,
          waypoints?.slice(1, waypoints?.length - 1)
        );
        const routeKeys = route.map(({ lat, lng }, idx) => {
          return `${idx}-${lat}-${lng}`;
        });
        await dispatch.travelingEntities.save({
          ...entity,
          route,
          routeKeys,
        });
      } catch (e) {
        console.error(e);
        message.error('Failed to generate route for editing traveling');
      } finally {
        this.setLoading(false);
      }
    },
    async generateRefPointsForTraveling(_, state) {
      this.setLoading(true);
      try {
        const { editId, locationIds = [] } = state.traveling;
        const { ids: entities } = state.travelingEntities;
        const { ids: locationEntities = {} } = state.locationEntities;
        if (!editId || !entities || !entities[editId]?.direction) {
          return;
        }

        const { route: points = [] } = entities[editId] as TravelingDtoType;
        const locations: LocationDtoType[] = locationIds
          .map((i) => locationEntities[i])
          .filter((x) => !!x);

        const NUMBER_OF_STATION_REF_PTS = 25;
        const NUMBER_OF_VIEW_REF_PTS = 25;

        const routeRefPoint: RouteRefPointResponseType = {};
        const views: LocationDtoType[] = [];
        let stationIdx = -1;
        let startIdx = -1;
        let endIdx = -1;
        let lastNearest = 0;
        for (let i = 0; i < locations.length; i++) {
          const location = locations[i];
          if (location.type === LOCATION_TYPE.VIEW) {
            views.push(location);
            continue;
          } else {
            stationIdx++;
          }

          routeRefPoint[location.code] = [];
          let nearestIdx = -1;
          for (let idx = lastNearest; idx < points.length; idx++) {
            const { locationIndex } = points[idx];
            if (locationIndex === stationIdx) {
              if (nearestIdx === -1) {
                nearestIdx = idx;
              } else {
                const prevPoint = points[nearestIdx];
                const prevDist = getDistanceBetween2Points(location as any, prevPoint);
                const currDist = getDistanceBetween2Points(location as any, points[idx]);
                if (currDist < prevDist) {
                  nearestIdx = idx;
                }
              }
            } else if (nearestIdx !== -1) {
              break;
            }
          }

          let fromIdx = nearestIdx - (NUMBER_OF_STATION_REF_PTS - 1) / 2;
          if (fromIdx < 0) fromIdx = 0;

          let toIdx = nearestIdx + (NUMBER_OF_STATION_REF_PTS - 1) / 2;
          if (toIdx >= points.length) toIdx = points.length - 1;
          lastNearest = toIdx;

          for (let j = fromIdx; j <= toIdx; j++) {
            const point = points[j];
            if (point) {
              routeRefPoint[location.code].push({
                point,
                pointIndex: j,
                dist: 0,
              });
            }
          }

          if (startIdx === -1) {
            startIdx = toIdx + 1;
          } else if (endIdx === -1) {
            endIdx = fromIdx - 1;
          }

          // if has both start and end stations but no view, set startIdx = toIdx + 1 and endIdx to -1
          if (!views.length && startIdx !== -1 && endIdx !== -1) {
            startIdx = toIdx + 1;
            endIdx = -1;
          }

          // process views between 2 stations
          if (views.length && startIdx !== -1 && endIdx !== -1) {
            const viewRefPoints: RouteRefPointType[] = points
              .slice(startIdx, endIdx + 1)
              .map((p, idx) => ({
                point: p,
                pointIndex: startIdx + idx,
                dist: 0,
              }));

            for (const view of views) {
              if (!viewRefPoints.length) {
                break;
              }

              routeRefPoint[view.code] = [];
              const latLng: LatLngType = {
                lat: view.lat!,
                lng: view.lng!,
              };
              const nearestViewIdx = viewRefPoints.reduce(
                (previousIdx, currentPoint, currentIdx) => {
                  const previousPoint = viewRefPoints[previousIdx];
                  const prevDist = getDistanceBetween2Points(latLng, previousPoint.point);
                  const currDist = getDistanceBetween2Points(latLng, currentPoint.point);
                  if (prevDist <= currDist) {
                    return previousIdx;
                  }

                  return currentIdx;
                },
                0
              );

              let from = nearestViewIdx - (NUMBER_OF_VIEW_REF_PTS - 1) / 2;
              if (from < 0) from = 0;

              let to = nearestViewIdx + (NUMBER_OF_VIEW_REF_PTS - 1) / 2;
              if (to >= viewRefPoints.length) to = viewRefPoints.length - 1;
              for (let j = from; j <= to; j++) {
                const refPoint = viewRefPoints[j];
                if (refPoint) {
                  routeRefPoint[view.code].push(refPoint);
                }
              }

              viewRefPoints.splice(0, to + 1);
            }

            startIdx = toIdx + 1;
            endIdx = -1;
            views.splice(0);
          }
        }

        await dispatch.travelingEntities.setRouteRefPoint({ id: editId, routeRefPoint });
        this.setRefPointsChanged(true);
      } catch (e) {
        console.error(e);
        message.error('Failed to get traveling details');
      } finally {
        this.setLoading(false);
      }
    },
    async addTravelingLocationRefPoints(payload: RouteRefPointType[], state) {
      const { editId, activeLocationId } = state.traveling;
      if (!editId || !activeLocationId) {
        return;
      }

      const location = state.locationEntities.ids[activeLocationId];
      if (!location) {
        return;
      }

      await dispatch.travelingEntities.addRouteRefPoint({
        id: editId,
        locationCode: location.code,
        refPoints: payload,
      });
      this.setRefPointsChanged(true);
    },
    async removeTravelingLocationRefPoint(
      payload: { locationCode: string; refPoint: RouteRefPointType },
      state
    ) {
      const { editId } = state.traveling;
      if (!editId) {
        return;
      }

      await dispatch.travelingEntities.removeRouteRefPoint({ id: editId, ...payload });
      this.setRefPointsChanged(true);
    },
    async updateTravelingLocationPosition(payload) {
      await dispatch.locationEntities.updatePosition(payload);
      this.setRouteChanged(true);
    },
    async updateTravelingDirection(payload, state) {
      const { editId } = state.traveling;
      if (!editId) {
        return;
      }

      await dispatch.travelingEntities.updateDirection({ id: editId, direction: payload });
      this.setRouteChanged(true);
    },
    async updateTravelingLocationRefPoint(
      payload: { locationCode: string; refPoint: RouteRefPointType },
      state
    ) {
      const { editId } = state.traveling;
      if (!editId) {
        return;
      }

      await dispatch.travelingEntities.updateRouteRefPoint({ id: editId, ...payload });
      this.setRefPointsChanged(true);
    },
    async updateLocationsAsync(_, state) {
      this.setLoading(true);
      try {
        const { editId, locationIds } = state.traveling;
        if (!editId) {
          return false;
        }
        const item = await dispatch.travelingEntities.updateLocations({ id: editId, locationIds });
        await this.setLocationsChanged(false);
        await this.setNewViewAdded(false);
        await this.setLocationIds((item.locations || []).map((x) => x.id));
        return true;
      } catch (e) {
        console.error(e);
        message.error('Failed to update traveling locations');
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async updateDirectionAsync(_, state) {
      this.setLoading(true);
      try {
        const { editId, locationIds } = state.traveling;
        if (!editId) {
          return false;
        }
        await dispatch.travelingEntities.saveDirection({ id: editId, locationIds });
        await this.setRouteChanged(false);
        return true;
      } catch (e) {
        console.error(e);
        message.error('Failed to update traveling route');
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async updateRouteRefPointAsync(_, state) {
      this.setLoading(true);
      try {
        const { editId } = state.traveling;
        if (!editId) {
          return false;
        }
        await dispatch.travelingEntities.updateReferencePoints(editId);
        this.setRefPointsChanged(false);
        return true;
      } catch (e) {
        console.error(e);
        message.error('Failed to update traveling reference points');
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async updateBackgroundSoundAsync(payload: FileModel | null, state) {
      this.setLoading(true);
      try {
        const { editId } = state.traveling;
        const { user } = state.app;
        if (!editId || !user) {
          return false;
        }

        const item = await dispatch.travelingEntities.updateBgSound({
          id: editId,
          data: {
            bgSound: payload,
          },
        });
        this.setAudio(item);
        this.setShowUpdateBgSoundModal(false);
        message.success('Background sound updated successfully!');
        return true;
      } catch (e) {
        console.error(e);
        message.error('Failed to update background sound');
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async getEditLocationAsync(locationId: number) {
      this.setLoading(true);
      try {
        const { data: location } = await getLocation(locationId);
        if (location) {
          this.setEditLocation(location);
        }
      } catch (e) {
        console.error(e);
        message.success('Failed to get location details.');
      } finally {
        this.setLoading(false);
      }
    },
    async updateLocationDetailsAsync(payload: LocationDtoType) {
      this.setLoading(true);
      try {
        await dispatch.locationEntities.updateDetails(payload);
        this.setNeedsUpdateForm(true);
        message.success('Location updated!');
      } catch (e) {
        console.error(e);
        message.success('Failed to update location.');
      } finally {
        this.setLoading(false);
      }
    },
  }),
});
